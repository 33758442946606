import Modal from "@/components/Modal"
import React, { Fragment, useEffect, useState } from "react"
import { paymentMethods } from "../UpgradeAccount"
import classNames from "classnames"
import { Radio } from "@/components/Alpha/View/GalleryFilter"
import { PaymentMethod, WorkspacePlanItem } from "@/api/sdk"
import { ETHIcon } from "@/components/shared/icons"
import IconButton from "@/components/IconButton"
import { useUpdatePaymentMethodMutation } from "@/queries"
import { useAuth } from "@/providers/authContext"
import { useQueryClient } from "@tanstack/react-query"
import { useToast } from "@/hooks"

interface ChangePaymentMethodModalProps {
  isOpen: boolean
  userPlan?: WorkspacePlanItem
  onClose: () => void
  onPaymentUrl?: (url: string) => void
}

const ChangePaymentMethodModal = ({ isOpen, onClose, onPaymentUrl, userPlan }: ChangePaymentMethodModalProps) => {
  const { userInfoQuery } = useAuth()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    userPlan?.currentSubscription.paymentMethod || paymentMethods[0].id,
  )
  const qc = useQueryClient()
  const toast = useToast()

  const { mutate: mutateUpdatePaymentMethod, isPending: isUpdating } = useUpdatePaymentMethodMutation({
    onSuccess: (data, variables) => {
      if (variables.data.paymentMethod === PaymentMethod.CRYPTO) {
        qc.invalidateQueries({ queryKey: ["user-plan"] })
        qc.invalidateQueries({ queryKey: ["payment-info"] })
        toast({
          status: "success",
          title: "Change completed",
          message: ["Your payment method has been changed successfully."],
        })
      }

      if (data.url) {
        onPaymentUrl?.(data.url)
      }
    },
  })

  useEffect(() => {
    if (isOpen) {
      setSelectedPaymentMethod(userPlan?.currentSubscription.paymentMethod || paymentMethods[0].id)
    }
  }, [isOpen])

  return (
    <Modal title="Change Payment Method" isOpen={isOpen} onClose={onClose}>
      <div>
        <p className="text-atherGray-300">The payment method will apply to subscription payments</p>
        <p className="text-atherGray-300">
          For 2C2P, when you setup, it will perform auto recurring payment when the payment period comes.
        </p>
        <p className="text-atherGray-300">You should change the payment method before the next payment period.</p>
        <div className="flex flex-col space-y-4 mt-4">
          {paymentMethods.map((method, index) => (
            <Fragment key={method.id}>
              {index !== 0 && <hr className="border-atherGray-800" />}
              <div
                className={classNames("py-2", {
                  "opacity-40 cursor-not-allowed": method.isDisabled,
                })}
              >
                <Radio
                  name={method.name}
                  labelClassName={"text-base font-semibold"}
                  containerClassName={classNames({
                    "cursor-not-allowed": method.isDisabled,
                  })}
                  value={method.id}
                  leftIcon={
                    <div className="ml-4 rounded-md overflow-hidden">
                      {method.id === PaymentMethod.CRYPTO ? (
                        <div className="w-8 h-8 p-0.5">
                          <div className="bg-[#627EEA] w-full h-full rounded-full flex items-center justify-center">
                            <ETHIcon />
                          </div>
                        </div>
                      ) : (
                        <img src={method.icon} alt="" className="w-8 h-8" />
                      )}
                    </div>
                  }
                  checked={selectedPaymentMethod === method.id}
                  onChange={() => {
                    if (method.isDisabled) return
                    setSelectedPaymentMethod(method.id)
                  }}
                />
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      <div className="mt-4 flex items-center justify-end space-x-2">
        <IconButton colorScheme="secondary" onClick={onClose}>
          Cancel
        </IconButton>
        <IconButton
          disabled={selectedPaymentMethod === userPlan?.currentSubscription.paymentMethod}
          isLoading={isUpdating}
          onClick={() => {
            mutateUpdatePaymentMethod({
              subscriptionId: userInfoQuery?.data?.subscription.id ?? "",
              data: {
                paymentMethod: selectedPaymentMethod,
              },
            })
          }}
        >
          Change
        </IconButton>
      </div>
    </Modal>
  )
}

export default ChangePaymentMethodModal
