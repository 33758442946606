import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import Link from "next/link"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useState } from "react"
import IconButton from "../IconButton"
import { Menu } from "../MenuFloating"
import { MenuIcon } from "../shared/icons"
import AppLogo from "./AppLogo"
import FeedBack from "./FeedBack"
import UserInfo from "./UserInfo"

export const menuRecipes = [
  {
    id: "anime",
    name: "Anime",
    enable: true,
  },
  {
    id: "art-toy",
    name: "ArtToy",
    enable: true,
  },
  {
    id: "ai-qr-code",
    name: "QR Code",
    enable: true,
  },
  {
    id: "commercial-photoshoot",
    name: "Photoshoot",
    enable: true,
  },
  {
    id: "logo-art",
    name: "Logo Art",
    enable: true,
  },
  {
    id: "miss-universe",
    name: "Miss Universe",
    enable: true,
  },
  {
    id: "spookifyme",
    name: "SpookifyMe",
    enable: true,
  },
]

const Navbar = () => {
  const router = useCustomRouter()
  const pathName = router.pathname as string
  const referralCode = router.query.referralCode as string
  const { user, loading } = useAuth()
  const qc = useQueryClient()

  const [isOpen, setIsOpen] = useState(false)
  return (
    <div
      className={
        "flex sticky top-0 left-0 max-w-full bg-[#000000] w-[100vw] sm:w-full flex-row items-center justify-between pt-4 px-4 z-[21]"
      }
    >
      <div className="flex w-full relative justify-between items-center h-[4.5rem] py-2 rounded-xl bg-[#313131]">
        <div className="flex items-center">
          <div className="flex lg:hidden px-2">
            <Menu
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              placement="bottom-end"
              listClassName="bg-[#1E1E1E] text-whiteAlpha-900 rounded-xl shadow-md landing"
              trigger={
                <IconButton title="More" className={classNames("bg-transparent text-white hover:bg-transparent")}>
                  <MenuIcon width={16} height={16} />
                </IconButton>
              }
            >
              {menuRecipes
                .filter(item => item.enable)
                .map(item => (
                  <div key={item.id} className="relative flex items-center justify-center">
                    <Link
                      className={classNames("text-[#EAE4D4] w-full p-1 rounded-md flex items-center", {
                        "bg-[#313131] font-[600]": pathName.includes(item.id),
                        " font-[300]": !pathName.includes(item.id),
                      })}
                      href={`/ai-recipes/${item.id}${referralCode ? `?referralCode=${referralCode}` : ""}`}
                      onClick={() => {
                        googleAnalytics.event({
                          action: "click",
                          category: "navbar_alpha",
                          label: "menu_recipes",
                          params: {
                            recipe: item.id,
                            recipe_name: item.name,
                          },
                        })
                        setIsOpen(false)
                      }}
                    >
                      {item.name}
                    </Link>
                  </div>
                ))}
            </Menu>
          </div>
          <div className="flex items-start lg:px-4">
            <AppLogo textLogo href="/" className="flex h-[4rem] p-0" size={1} />
          </div>
        </div>

        <div className="hidden lg:flex items-center justify-center space-x-4 xl:space-x-8">
          {menuRecipes
            .filter(item => item.enable)
            .map(item => (
              <div key={item.id} className="flex items-center relative justify-center">
                <Link
                  className={classNames("text-[#EAE4D4]", {
                    "border-b font-[600]": pathName.includes(item.id),
                    " font-[300]": !pathName.includes(item.id),
                  })}
                  href={`/ai-recipes/${item.id}${referralCode ? `?referralCode=${referralCode}` : ""}`}
                  onClick={() => {
                    googleAnalytics.event({
                      action: "click",
                      category: "navbar",
                      label: "menu_recipes",
                      params: {
                        recipe: item.id,
                        recipe_name: item.name,
                      },
                    })
                  }}
                >
                  {item.name}
                </Link>
              </div>
            ))}
        </div>
        <div className="flex justify-end lg:min-w-[12rem]">
          <UserInfo />
        </div>
      </div>
      {user && <FeedBack />}
    </div>
  )
}

export default Navbar
