import React, { useState } from "react"
import Modal from "../Modal"
import { usePaymentSuccessStore } from "@/stores"
import IconButton from "../IconButton"
import { useGetUserPlanQuery } from "@/queries"
import { useAuth } from "@/providers/authContext"
import { BillingCycle, ProductType, SubscriptionType, WorkspaceSubscriptionItem } from "@/api/sdk"
import { useWeb3Modal } from "@web3modal/wagmi/react"
import { useAccount } from "wagmi"
import { useClearUrlQuery } from "@/hooks/useQuery"
import Link from "next/link"
import dynamic from "next/dynamic"
import { useSetting } from "./SettingProvider"

const CryptoPaymentModal = dynamic(() => import("../ModalsPortal/CryptoPaymentModal"), { ssr: false })

const getRenewCost = (currentSub: WorkspaceSubscriptionItem | undefined) => {
  let amount = 0
  if (!currentSub) {
    return amount
  }

  if (currentSub.billingCycle === BillingCycle.Monthly) {
    amount = parseInt(currentSub.monthlyCost)
  }
  if (currentSub.billingCycle === BillingCycle.BiYearly) {
    amount = parseInt(currentSub.biYearlyCost)
  }
  if (currentSub.billingCycle === BillingCycle.Yearly) {
    amount = parseInt(currentSub.yearlyCost)
  }
  return amount * currentSub.seatCount
}

const RenewSubscriptionModal = () => {
  const { isOpen, setIsOpen } = usePaymentSuccessStore()
  const { userInfoQuery } = useAuth()
  const { open } = useWeb3Modal()
  const [isLoading, setIsLoading] = useState(false)
  const { isConnected } = useAccount()
  const clearUrl = useClearUrlQuery()
  const [cryptoPayment, setCryptoPayment] = useState<{
    productType: ProductType
    productId: string
    workspaceId?: string
    subscriptionType?: SubscriptionType
    billingCycle?: BillingCycle
    seatCount?: number
  } | null>(null)

  const { userPlan } = useSetting()

  const onClose = () => {
    clearUrl("notificationId")
    setIsLoading(false)
    setIsOpen(false)
  }

  const onConfirm = () => {
    // crypto payment
    if (!isConnected) {
      open()
      return
    }
    if (!userPlan) {
      return
    }

    setCryptoPayment({
      productType: ProductType.Subscription,
      productId: userPlan.currentSubscription.subscriptionPackageId,
      workspaceId: userInfoQuery?.data?.currentWorkspace.id,
      subscriptionType: SubscriptionType.Renew,
      billingCycle: userPlan.currentSubscription.billingCycle as BillingCycle,
      seatCount: userPlan.currentSubscription.seatCount,
    })

    return
  }

  return (
    <>
      <Modal className="max-w-md" title={"Renew subscription"} isOpen={isOpen} onClose={onClose}>
        <div className="text-atherGray-300">
          <div className="space-y-2">
            <div className="flex items-center justify-between text-atherGray-100">
              <p className="font-semibold">Current plan</p>
              <p>{userPlan?.currentSubscription.subscriptionPackageName}</p>
            </div>
            <div className="flex items-center justify-between text-atherGray-100">
              <p className="font-semibold">Total seat</p>
              <p>{userPlan?.currentSubscription.seatCount}</p>
            </div>
            <div className="flex items-start justify-between text-atherGray-100">
              <p className="font-semibold">Current subscription</p>
              <div className="text-right">
                <p>Monthly</p>
                <p className="text-xs">${userPlan?.currentSubscription.monthlyCost} /month/seat</p>
              </div>
            </div>
          </div>
          <hr className="border-atherGray-800 my-4" />
          <div className="flex items-center justify-between text-atherGray-100">
            <p className="font-semibold">Total</p>
            <p className="font-semibold">${getRenewCost(userPlan?.currentSubscription)}</p>
          </div>
          <hr className="border-atherGray-800 my-4" />
          <div className="flex items-center justify-between text-atherGray-100">
            <p className="font-semibold">Payment method</p>
            <p className="font-semibold">Crypto</p>
          </div>
        </div>
        <div className="mt-6 flex items-center space-x-2 justify-end">
          <IconButton disabled={isLoading} onClick={onClose} colorScheme="secondary">
            Cancel
          </IconButton>
          <IconButton isLoading={isLoading} onClick={() => onConfirm()}>
            Renew
          </IconButton>
        </div>
        <p className="text-atherGray-500 text-center mt-4 text-xs">
          By continuing, you agree to GAIA&apos;s{" "}
          <Link href="/terms-of-service" target="_blank" className="underline text-atherGray-100">
            terms
          </Link>
        </p>
      </Modal>
      {cryptoPayment && (
        <CryptoPaymentModal
          cryptoPayment={cryptoPayment}
          onClose={() => setCryptoPayment(null)}
          onSuccessfulPayment={() => {
            setIsLoading(true)
          }}
        />
      )}
    </>
  )
}

export default RenewSubscriptionModal
