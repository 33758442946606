import { ChatRoomRole, ImageOwner, ResourceRole, SharingRole } from "@/api/sdk"
import UserBadge from "@/components/UserBadge"
import { ArrowDownIcon } from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import { useState } from "react"
import { Menu } from "../../MenuFloating"
import { FullRoleType } from "./ListUserShare"

export interface ShareUserItemProps {
  user: ImageOwner & { role?: FullRoleType | null; isGuest?: boolean }
  onClick?: () => void
  className?: string
  onUnshare?: () => void
  canShare: boolean
  availableRoles?: {
    label: string
    value: FullRoleType
  }[]
  onUpdateRole?: (role: FullRoleType) => void
}

const ShareUserItem = ({
  user,
  onClick,
  className,
  onUnshare,
  canShare,
  availableRoles,
  onUpdateRole,
}: ShareUserItemProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const renderRoleMenu = () => {
    if (user.role === "owner") {
      return (
        <div className="flex items-center text-white">
          <p className="text-xs font-normal">Owner</p>
        </div>
      )
    }

    if (canShare) {
      return (
        <Menu
          isOpen={isOpen}
          listClassName="bg-atherGray-900 rounded-2xl p-2 border border-atherGray-850 space-y-2 w-48"
          setIsOpen={setIsOpen}
          trigger={
            <button
              className={cn(
                "flex items-center py-0.5 px-1 hover:bg-atherGray-850 rounded-md cursor-pointer select-none",
                {
                  "bg-atherGray-850": isOpen,
                },
              )}
            >
              <span className="mr-1 text-xs font-normal capitalize">{user.role}</span>
              <span className="text-atherGray-500">
                <ArrowDownIcon width={12} height={12} />
              </span>
            </button>
          }
        >
          {!user.isGuest &&
            availableRoles?.map(role => (
              <button
                type="button"
                key={role.value}
                onClick={e => {
                  e.stopPropagation()

                  onUpdateRole?.(role.value as SharingRole)
                  setIsOpen(false)
                }}
                className={cn(
                  "group hover:bg-atherGray-850 text-left rounded-2xl px-4 py-1.5 text-atherGray-0 w-full",
                  {
                    "bg-atherGray-850": role.value === user.role,
                  },
                )}
              >
                <span
                  className={cn("text-sm capitalize text-atherGray-300 group-hover:text-atherGray-0", {
                    "text-atherGray-0": role.value === user.role,
                  })}
                >
                  {role.label}
                </span>
              </button>
            ))}
          {!user.isGuest && <hr className="border-atherGray-800 my-2" />}
          <div className="">
            <button
              onClick={e => {
                e.stopPropagation()
                onUnshare?.()
                setIsOpen(false)
              }}
              className={
                "flex items-center hover:bg-atherGray-850 bg-transparent rounded-2xl px-4 py-1.5 pr-8 w-full text-red-500 text-sm"
              }
            >
              <p className="">Remove access</p>
            </button>
          </div>
        </Menu>
      )
    }

    return (
      <div className="flex items-center text-white">
        <p className="text-xs font-normal capitalize">{user.role}</p>
      </div>
    )
  }

  return (
    <div
      className={cn("flex items-center space-x-2", className)}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onClick && onClick()
      }}
    >
      <div className="flex items-center flex-1 select-none">
        <UserBadge
          picture={user.picture}
          isGuest={user.isGuest}
          name={user.name}
          email={user.email || user.username}
          lightText
          size="sm"
        />
      </div>
      {renderRoleMenu()}
    </div>
  )
}

export default ShareUserItem
