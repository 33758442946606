import { PaymentMethod, PaymentPlatform } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { ETHIcon } from "@/components/shared/icons"
import { useAuth } from "@/providers/authContext"
import { useGetPaymentInfoQuery, useUpdatePaymentMethodMutation } from "@/queries"
import { paymentMethods } from "../UpgradeAccount"

interface BillingInfoModalProps {
  isOpen: boolean
  onClose: () => void
  onPaymentUrl?: (url: string) => void
}

const BillingInfoModal = ({ isOpen, onClose, onPaymentUrl }: BillingInfoModalProps) => {
  const { userInfoQuery } = useAuth()

  const { data: workspacePaymentInfo = [] } = useGetPaymentInfoQuery({
    variables: {
      workspaceId: userInfoQuery?.data?.currentWorkspace.id ?? "",
    },
  })

  const { mutate: mutateUpdatePaymentMethod, isPending: isUpdating } = useUpdatePaymentMethodMutation({
    onSuccess: data => {
      onPaymentUrl?.(data.url)
    },
  })

  return (
    <Modal title="Update Card Detail" isOpen={isOpen} onClose={onClose}>
      <div>
        <p className="text-atherGray-300">
          This applies to fiat payments with 2C2P. When you change your card information (change to a new card), you
          will be charged a small fee (1 USD) to make this request.
        </p>
        <p className="text-atherGray-300">This amount will be refunded to your account afterwards.</p>
        <div className="flex flex-col space-y-4 mt-4">
          {workspacePaymentInfo.map(item => (
            <div className="flex items-center justify-between" key={item.id}>
              <div className="flex items-center">
                <div className="rounded-md overflow-hidden">
                  {item.platform !== PaymentPlatform.Value2C2P ? (
                    <div className="w-8 h-8 p-0.5">
                      <div className="bg-[#627EEA] w-full h-full rounded-full flex items-center justify-center">
                        <ETHIcon />
                      </div>
                    </div>
                  ) : (
                    <img
                      src={paymentMethods.find(method => method.id === PaymentMethod.Value2C2P)?.icon}
                      alt=""
                      className="w-8 h-8"
                    />
                  )}
                </div>
                <p className="ml-4 font-semibold">{item.platform}</p>
              </div>
              <p>{item.creditCard}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end space-x-2">
        <IconButton colorScheme="secondary" onClick={onClose}>
          Cancel
        </IconButton>
        <IconButton
          disabled={workspacePaymentInfo.length === 0}
          isLoading={isUpdating}
          onClick={() => {
            mutateUpdatePaymentMethod({
              subscriptionId: userInfoQuery?.data?.subscription.id ?? "",
              data: {
                paymentMethod: PaymentMethod.Value2C2P,
              },
            })
          }}
        >
          Update Now
        </IconButton>
      </div>
    </Modal>
  )
}

export default BillingInfoModal
