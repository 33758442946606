import { BillingCycle, SubscriptionStatus } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { isProduction } from "@/components/Layouts/LazyProviders"
import { useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useAuth } from "@/providers/authContext"
import { useUnsubscribePlanMutate } from "@/queries"
import { usePaymentSuccessStore } from "@/stores"
import format from "date-fns/format"
import dynamic from "next/dynamic"
import React, { useEffect, useState } from "react"
import { useSetting } from "../SettingProvider"
import { paymentMethods } from "../UpgradeAccount"
import BillingInfoModal from "./BillingInfoModal"
import ChangePaymentMethodModal from "./ChangePaymentMethodModal"
import UnsubscribeModal from "./UnsubscribeModal"

const Payment2C2PModal = dynamic(() => import("../Plans/Payment2C2PModal"))

const loadingContent = (value: React.ReactNode, isLoading: boolean) => {
  if (isLoading) return "..."
  return value
}

const UserPlan = () => {
  const { userInfoQuery, user } = useAuth()
  const [paymentUrl, setPaymentUrl] = useState<string | null>(null)
  const toast = useToast()
  const [modal, setModal] = useState<"UNSUBSCRIBE" | "CHANGE_METHOD" | "BILLING_INFO" | null>(null)
  const router = useCustomRouter()

  const setIsOpen = usePaymentSuccessStore(state => state.setIsOpen)

  const {
    userPlan,
    isLoadingUserPlan,
    isSuccessUserPlan,
    refetchUserPlan,
    refetchCurrentWorkspace,
    currentWorkspaceDetail,
  } = useSetting()

  const { mutate: mutateUnsubscribe, isPending: isMutatingUnsubscribe } = useUnsubscribePlanMutate({
    onSuccess: () => {
      refetchUserPlan()
      refetchCurrentWorkspace()
      setModal(null)
      toast({
        title: "Unsubscribe successfully",
        status: "success",
      })
    },
  })

  const handleUnsubscribe = () => {
    if (!userInfoQuery?.data?.currentWorkspace.id || !userPlan?.currentSubscription.id) return

    mutateUnsubscribe({
      data: {
        workspaceId: userInfoQuery?.data?.currentWorkspace.id,
        subscriptionPackageId: userPlan.currentSubscription.subscriptionPackageId,
      },
      userUid: user?.uid ?? "",
    })
  }

  useEffect(() => {
    if (!router.isReady) return

    if (isSuccessUserPlan) {
      if (userPlan?.currentSubscription.status === SubscriptionStatus.PastDue && !!router.query.notificationId) {
        setIsOpen(true)
        return
      }
      if (currentWorkspaceDetail?.owner?.uid !== userInfoQuery?.data?.uid) {
        router.push("/settings/account?tab=Plans")
      }
    }
  }, [isSuccessUserPlan, router.isReady])

  const isTrial =
    userPlan?.isTrial === true && !!userPlan?.trialEndedAt && new Date(userPlan?.trialEndedAt) > new Date()

  const currentPaymentMethod = paymentMethods.find(i => i.id === userPlan?.currentSubscription.paymentMethod)

  return (
    <>
      <div className="w-full max-w-[40rem] mb-8">
        <div className="flex items-center bg-atherPurple-500 p-4 rounded-t-xl overflow-hidden transform-gpu relative">
          <div className="pointer-events-none bg-[rgba(255,255,255,0.08)] absolute top-1/2 -translate-y-1/2  left-[-0.5rem] w-[15rem] h-[15rem] rounded-full" />
          <div className="pointer-events-none bg-[rgba(255,255,255,0.08)] absolute top-1/2 -translate-y-1/2 left-[-0.5rem] w-[20rem] h-[20rem] rounded-full" />
          <div className="pointer-events-none bg-[rgba(255,255,255,0.08)] absolute top-1/2 -translate-y-1/2 left-[-0.5rem] w-[25rem] h-[25rem] rounded-full" />
          <div className="flex-1 mr-2">
            <h3 className="text-2xl font-semibold">
              {loadingContent(
                (userPlan?.currentSubscription.subscriptionPackageName ?? "") + (isTrial ? " (TRIAL)" : ""),
                isLoadingUserPlan,
              )}
            </h3>
            {/* <Link  href={"#"} className="text-sm font-semibold underline">
              Learn more about your plan
            </Link> */}
          </div>
          {userPlan?.currentSubscription.status === SubscriptionStatus.PastDue && (
            <IconButton
              isLoading={isLoadingUserPlan}
              onClick={() => setIsOpen(true)}
              className="bg-blackAlpha-600 py-2.5 mr-2 rounded-xl hover:bg-blackAlpha-500 active:bg-blackAlpha-500"
            >
              Renew
            </IconButton>
          )}
          {userPlan?.currentSubscription?.subscriptionPackageId?.toLowerCase() !== "free" && !isTrial ? (
            userPlan?.currentSubscription.requestedCancel ? null : (
              <IconButton
                isLoading={isLoadingUserPlan}
                onClick={() => setModal("UNSUBSCRIBE")}
                className="bg-black text-red-500 py-2.5 rounded-xl hover:bg-atherGray-900 active:bg-atherGray-900"
              >
                Unsubscribe
              </IconButton>
            )
          ) : (
            <IconButton
              isLoading={isLoadingUserPlan}
              onClick={() => router.push("/settings/account?tab=Plans")}
              className="bg-blackAlpha-600 py-2.5 rounded-xl hover:bg-blackAlpha-500 active:bg-blackAlpha-500"
            >
              Upgrade your plan
            </IconButton>
          )}
        </div>
        <div className=" bg-atherGray-900 p-4 rounded-b-xl flex text-sm">
          <div className="flex-1">
            <p className="font-semibold mb-1">Status</p>
            <p className="text-atherGray-300 capitalize">
              {loadingContent(userPlan?.currentSubscription.status ?? "", isLoadingUserPlan)}
            </p>
          </div>
          <div className="flex-1">
            <p className="font-semibold mb-1">
              {userPlan?.currentSubscription.requestedCancel ? "Cancel on" : "Renew on"}
            </p>
            <p className="text-atherGray-300">
              {loadingContent(
                userPlan?.currentSubscription.requestedCancel?.scheduledAt
                  ? format(new Date(userPlan.currentSubscription.requestedCancel.scheduledAt), "MMM dd, yyyy")
                  : userPlan?.currentSubscription.endAt
                    ? format(new Date(userPlan.currentSubscription.endAt), "MMM dd, yyyy")
                    : isTrial
                      ? format(new Date(userPlan?.trialEndedAt), "MMM dd, yyyy")
                      : "",
                isLoadingUserPlan,
              )}
            </p>
          </div>
          <div className="flex-1">
            <p className="font-semibold mb-1">Price</p>
            <p className="text-atherGray-300">
              {loadingContent(
                `$${
                  userPlan?.currentSubscription.billingCycle === BillingCycle.Monthly
                    ? userPlan.currentSubscription.monthlyCost
                    : userPlan?.currentSubscription.billingCycle === BillingCycle.BiYearly
                      ? userPlan.currentSubscription.biYearlyCost
                      : userPlan?.currentSubscription.billingCycle === BillingCycle.Yearly
                        ? userPlan?.currentSubscription.yearlyCost
                        : "0"
                }/${userPlan?.currentSubscription.billingCycle}`,
                isLoadingUserPlan,
              )}
            </p>
          </div>
          <div className="flex-1">
            <p className="font-semibold mb-1">Payment</p>
            <div className="flex items-center">
              {loadingContent(
                currentPaymentMethod ? (
                  <>
                    <img
                      src={currentPaymentMethod?.icon}
                      alt={userPlan?.currentSubscription.paymentMethod}
                      className="w-4 h-4"
                    />
                    <p className="text-atherGray-300 ml-2">{currentPaymentMethod?.name}</p>
                  </>
                ) : (
                  "Free of charge"
                ),
                isLoadingUserPlan,
              )}
            </div>
          </div>
        </div>

        {!isProduction && (
          <div className="flex items-center space-x-4 mt-4">
            <IconButton onClick={() => setModal("CHANGE_METHOD")}>Change Payment Method</IconButton>
            <IconButton onClick={() => setModal("BILLING_INFO")}>Change Billing Info</IconButton>
          </div>
        )}
      </div>
      <ChangePaymentMethodModal
        userPlan={userPlan}
        isOpen={modal === "CHANGE_METHOD"}
        onClose={() => setModal(null)}
        onPaymentUrl={setPaymentUrl}
      />
      <BillingInfoModal isOpen={modal === "BILLING_INFO"} onClose={() => setModal(null)} onPaymentUrl={setPaymentUrl} />
      <Payment2C2PModal isOpen={paymentUrl} onClose={() => setPaymentUrl(null)} />
      <UnsubscribeModal
        isOpen={modal === "UNSUBSCRIBE"}
        title={"Unsubscribe plan"}
        description={
          <>
            <p className="mb-2">
              When you unsubscribe the{" "}
              <span className="text-atherGray-0 font-semibold">
                {userPlan?.currentSubscription.subscriptionPackageName}
              </span>
            </p>
            <ul className="space-y-2">
              {userPlan?.currentSubscription.subscriptionPackageId === "newbie" ? (
                <li className="list-inside">You can not generate images.</li>
              ) : (
                <>
                  <li className="list-inside">
                    Your plan will be reverted to Newbie plan and and use the corresponding utilities.
                  </li>
                  <li className="list-inside">And all members will be changed to Guests.</li>
                  <li className="list-inside">
                    All files uploaded and generated by workspace members will be transferred to Workspace Owner.
                  </li>
                  <li className="list-inside">
                    If your current storage capacity exceeds the Newbie plan (
                    {parseInt(userPlan?.currentSubscription.cloudStorage ?? "0") / 1024 / 1024 / 1024}GB /user), all
                    oldest data will be deleted. You have 14 days to backup your data from the time you unsubscribe.
                  </li>
                </>
              )}
            </ul>
            <p className="mt-2 font-semibold">Are you sure to unsubscribe plan?</p>{" "}
          </>
        }
        confirmText={"Unsubscribe"}
        onClose={() => {
          setModal(null)
        }}
        onConfirm={() => handleUnsubscribe()}
        isLoading={isMutatingUnsubscribe}
      />
    </>
  )
}

export default UserPlan
